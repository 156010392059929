import * as React from "react"

import { useEffect, useContext } from "react"
import { Link, useStaticQuery, graphql } from "gatsby"
import CursorContext from "../../context/cursorContext"

// Styles
import "./_journal-posts.scss"

// Components
import Image from "gatsby-image"
import { $desktop } from "../../utils/breakpoints"
import { Section, Container } from "../StyledComponents/layoutComponentStyles"

// Animation
import { gsap } from "gsap"
import { ScrollTrigger } from "gsap/ScrollTrigger"
import { motion } from "framer-motion"

interface JournalPostsProps {}

/*



 





*/

const JournalPosts: React.FunctionComponent<JournalPostsProps> = () => {
  const data = useStaticQuery(graphql`
    {
      allSanityPosts(sort: { fields: order, order: ASC }) {
        edges {
          node {
            postCategories {
              title
            }
            mainImage {
              altText
              image {
                asset {
                  fluid(maxWidth: 1000) {
                    ...GatsbySanityImageFluid
                  }
                }
              }
            }
            publishedAt(formatString: "DD MMM")
            slug {
              current
            }
            title
            excerpt
            author {
              name
            }
            timeToRead
          }
        }
      }
    }
  `)

  // Context
  const { setCursorState } = useContext(CursorContext)

  // Effects
  useEffect(() => {
    ScrollTrigger.batch(".o-journal-post.row-n", {
      start: () => {
        if ($desktop) {
          return "0px, 75%"
        } else {
          return "0px, 75%"
        }
      },
      onEnter: batch =>
        gsap.to(batch, {
          opacity: 1,
          y: "0rem",
          ease: "expo.out",
          duration: 0.6,
          stagger: 0.125,
        }),
    })

    const posts = gsap.utils.toArray(".o-journal-post.row-n")
    gsap.set(posts, { opacity: 0, y: "35rem" })

    gsap.from(".o-journal-post.row-1", {
      opacity: 0,
      y: "35rem",
      ease: "expo.out",
      duration: 0.6,
      stagger: 0.125,
      delay: 1.25,
    })
  }, [])

  return (
    <Section>
      <Container fourteenClm>
        <motion.div
          initial={{ opacity: 0 }}
          animate={{
            opacity: 1,
            transition: { duration: 0.3, ease: "linear", delay: 0.9 },
          }}
          exit={{ opacity: 0, transition: { duration: 0.3, ease: "linear" } }}
          className='o-journal-posts'
        >
          {data.allSanityPosts.edges.map(({ node: post }: any, index: number) => (
            <div
              className={`o-journal-post ${
                window.innerWidth > 480 ? (index <= 1 ? "row-1" : "row-n") : index <= 0 ? "row-1" : "row-n"
              }`}
              key={post.slug.current}
            >
              <Link
                to={`/${post.slug.current}/`}
                onMouseEnter={() => setCursorState?.("is-post")}
                onMouseLeave={() => setCursorState?.("")}
              >
                <div className='c-post-img'>
                  <Image fluid={post.mainImage.image.asset.fluid} alt={post.title} />
                </div>
              </Link>
              <div className='c-post-details'>
                <div className='c-post-details__banner'>
                  <Link
                    to={`/${post.slug.current}/`}
                    onMouseEnter={() => setCursorState?.("is-link")}
                    onMouseLeave={() => setCursorState?.("")}
                  >
                    <h6>{post.title}</h6>
                  </Link>
                  <p className='o-accent'>{post.publishedAt}</p>
                </div>
                <p className='post-excerpt'>{post.excerpt}</p>

                {window.innerWidth > 480 ? (
                  <p>
                    {post.author.name} <span> in </span>
                    {post.postCategories.map((cat: any) => (
                      <span key={cat.title}>{cat.title}</span>
                    ))}
                    <span>&nbsp;&nbsp; • &nbsp;&nbsp;</span>
                    {post.timeToRead} min read
                  </p>
                ) : (
                  <p>
                    {post.publishedAt}
                    <span>&nbsp;&nbsp; • &nbsp;&nbsp;</span>
                    {post.timeToRead} min read
                  </p>
                )}
              </div>
            </div>
          ))}
        </motion.div>
      </Container>
    </Section>
  )
}

export default JournalPosts
