import * as React from "react"
import { useEffect, useContext } from "react"
import ReactGA from "react-ga"

// Context
import ScrollSafeContext from "../context/scrollSafeContext"

// Components
import SEO from "../components/seo"
import JournalHero from "../components/JournalHero"
import JournalPosts from "../components/JournalPosts"

// Animations
import { ScrollTrigger } from "gsap/ScrollTrigger"

// Interfaces
interface JounralPageProps {}

// SEO
const seo = {
  title: "Journal",
  description: "Want to know what we're thinking? We provide insight and opinion into the world of design and development.",
  keywords: [
    "design blog",
    "design how-to",
    "design tutorial",
    "creative insight",
    "digital design studio",
    "web design shrewsbury",
    "web design midlands",
    "freelance web developer",
  ],
}

/*









*/

const JournalPage: React.FunctionComponent<JounralPageProps> = () => {
  // Context
  const { scrollSafe, setScrollSafe } = useContext(ScrollSafeContext)

  // Effects
  useEffect(() => {
    setTimeout(() => {
      ScrollTrigger.refresh()
      setScrollSafe?.(true)
    }, 600)

    ReactGA.pageview(window.location.pathname + window.location.search)

    return () => {
      setScrollSafe?.(false)
    }
  }, [])

  return (
    <>
      <SEO title={seo.title} description={seo.description} keywords={seo.keywords} />
      {scrollSafe && (
        <>
          <JournalHero />
          <JournalPosts />
        </>
      )}
    </>
  )
}

export default JournalPage
