import * as React from "react"

// Styles
import "./_journal-hero.scss"

// Components
import { Section, Container } from "../StyledComponents/layoutComponentStyles"
import ScrollPrompt from "../ScrollPrompt"

// Animations
import { motion } from "framer-motion"
import { verticalTagline, delayedFade, slideTitleParent, slideTitleChild } from "../../animations/globalVariants"
import { $mobile } from "../../utils/breakpoints"

interface JournalHeroProps {}

/*









*/

const JournalHero: React.FunctionComponent<JournalHeroProps> = () => {
  return (
    <Section fullHeight alignCenter>
      <Container sixteenClm fullHeight>
        <motion.div
          initial='initial'
          animate='animate'
          exit='exit'
          variants={slideTitleParent}
          className='o-hero-title-wrapper -journal'
        >
          {!$mobile ? <TitleDesktopTablet /> : <TitleMobile />}
        </motion.div>
        <div className='o-vertical-tagline'>
          <motion.span initial='initial' animate='animate' exit='exit' variants={verticalTagline}>
            The word on the street
          </motion.span>
        </div>
        {window.innerWidth > 480 ? <ScrollPrompt variants={delayedFade} /> : null}
      </Container>
    </Section>
  )
}

export default JournalHero

export const TitleDesktopTablet: React.FunctionComponent = () => {
  return (
    <>
      <div className='u-overflow-reveal'>
        <motion.h1 variants={slideTitleChild}>Want to know what</motion.h1>
      </div>
      <div className='u-overflow-reveal'>
        <motion.h1 variants={slideTitleChild}>
          we're <span className='o-outline-type'>thinking?</span>
        </motion.h1>
      </div>
    </>
  )
}

export const TitleMobile: React.FunctionComponent = () => {
  return (
    <>
      <div className='u-overflow-reveal'>
        <motion.h1 variants={slideTitleChild}>Want to know</motion.h1>
      </div>
      <div className='u-overflow-reveal'>
        <motion.h1 variants={slideTitleChild}>what we're</motion.h1>
      </div>
      <div className='u-overflow-reveal'>
        <motion.h1 variants={slideTitleChild}>
          <span className='o-outline-type'>thinking?</span>
        </motion.h1>
      </div>
    </>
  )
}
